export default {
  viewBox: "0 0 100 125",
  enableBackground: "new 0 0 100 100",
  children: [
    {
      name: "g",
      attribs: {},
      children: [
        {
          name: "path",
          attribs: {
             d: "M22.746,58.596c0.693-0.425,1.505-0.675,2.376-0.675h49.756c0.87,0,1.683,0.25,2.376,0.675   C77.138,52.883,64.983,48.266,50,48.266S22.863,52.882,22.746,58.596z"
          }
        },
        {
          name: "path",
          attribs: {
             d: "M64.3,16.144l4.284-1.854c1.119-0.486,1.119-1.279,0-1.765L52.037,5.364c-1.12-0.485-2.952-0.485-4.073,0l-16.547,7.161   c-1.12,0.486-1.12,1.278,0,1.765l1.254,0.542l-0.105,0.842c-0.48,0.082-0.847,0.5-0.847,1.002c0,0.453,0.295,0.839,0.705,0.969   l-0.76,10.661c-0.032,0.447,0.057,1.155,0.197,1.579l0.619,1.853c0.14,0.424,0.37,0.424,0.512,0l0.618-1.853   c0.141-0.423,0.231-1.131,0.198-1.579l-0.76-10.661c0.406-0.131,0.705-0.516,0.705-0.969c0-0.502-0.366-0.921-0.848-1.002   l-0.097-0.784l2.894,1.254l1.419,0.614v4.775c-0.596,1.543-0.926,3.219-0.926,4.972c0,7.624,6.181,17.548,13.805,17.548   c7.624,0,13.805-9.924,13.805-17.548c0-1.751-0.329-3.424-0.924-4.966v-4.781L64.3,16.144z"
          }
        },
        {
          name: "path",
          attribs: {
             d: "M73.495,94.061c0-0.519-0.421-0.939-0.939-0.939H27.444c-0.519,0-0.94,0.421-0.94,0.939S26.925,95,27.444,95h45.111   C73.074,95,73.495,94.579,73.495,94.061z"
          }
        },
        {
          name: "path",
          attribs: {
             d: "M77.254,59.849c-0.693-0.425-1.506-0.674-2.376-0.674H25.122c-0.871,0-1.683,0.249-2.376,0.674   c-1.313,0.804-2.194,2.247-2.194,3.896v0.106l0.018,0.106L24.7,87.91c0.062,2.467,2.087,4.454,4.569,4.454h41.462   c2.481,0,4.508-1.987,4.569-4.454l4.129-23.952l0.019-0.106v-0.106C79.448,62.096,78.566,60.652,77.254,59.849z M50,77.322   c-1.086,0-1.968-0.88-1.968-1.968c0-1.085,0.882-1.965,1.968-1.965s1.966,0.88,1.966,1.965C51.967,76.442,51.087,77.322,50,77.322z"
          }
        },
      ]
    }
  ]
}


export default {
  viewBox: "0 0 64 80",
  "children": [
    {
      name: "g",
      attribs: {},
      "children": [
        {
          name: "g",
          attribs: {},
          "children": [
            {
              name: "circle",
              attribs: {
                cx: "32.07",
                cy: "9.497",
                r: "4.411"
              }
            },
            {
              name: "path",
              attribs: {
                d: "M43.341,24.088l-3.989-6.577c0,0-1.204-1.226-4.53-1.902l-1.971,4.79l-0.327-2.761l0.728-1.099    c0.141-0.242,0.106-0.553-0.101-0.762c-0.122-0.123-0.295-0.184-0.469-0.184h-1.363c-0.174,0-0.346,0.06-0.469,0.183    c-0.207,0.209-0.241,0.52-0.101,0.762l0.728,1.099l-0.327,2.763l-1.937-4.711c-3.029,0.707-4.368,1.783-4.368,1.783l-4.162,6.47    c-0.348,0.541-0.352,1.233-0.012,1.779l4.162,6.66c0.307,0.492,0.828,0.764,1.366,0.778v19.045c0,1.073,0.87,1.943,1.943,1.943    h0.273c1.01,0,1.852-0.774,1.936-1.781l1.029-14.389c0.053-0.319,0.319-0.585,0.638-0.585c0.319,0,0.638,0.266,0.638,0.585    l1.252,14.424c0.101,0.992,0.936,1.746,1.933,1.746h0c1.073,0,1.943-0.87,1.943-1.943v-19.06c0.466-0.056,0.907-0.308,1.188-0.732    l4.335-6.548C43.66,25.33,43.674,24.637,43.341,24.088z M26.198,28.299l-2.153-3.445l2.153-3.346V28.299z M37.783,28.192v-3.604    c0-0.05,0.007-0.1,0.02-0.147v-3.079l2.152,3.55L37.783,28.192z"
              }
            }
          ]
        },
        {
          name: "circle",
          attribs: {
            cx: "17.488", cy: "6.081",
            r: "4.411"
          }
        },
        {
          name: "circle",
          attribs: {
            cx: "46.652", cy: "6.081",
            r: "4.411"
          }
        },
        {
          name: "path",
          attribs: {
            d: "M19.162,26.665c-0.702-1.127-0.691-2.572,0.025-3.685l4.318-6.712l0.225-0.181c0.094-0.076,0.628-0.488,1.658-0.971   l-0.619-1.02c0,0-1.204-1.226-4.53-1.902l-1.971,4.79l-0.327-2.761l0.728-1.099c0.141-0.242,0.106-0.553-0.101-0.762   c-0.122-0.123-0.295-0.184-0.469-0.184h-1.363c-0.174,0-0.346,0.06-0.469,0.183c-0.207,0.209-0.241,0.52-0.101,0.762l0.728,1.099   l-0.327,2.763l-1.937-4.711c-3.029,0.707-4.368,1.783-4.368,1.783l-4.162,6.47c-0.348,0.541-0.352,1.233-0.012,1.779l4.162,6.66   c0.307,0.492,0.828,0.764,1.366,0.778V48.79c0,1.073,0.87,1.943,1.943,1.943h0.273c1.01,0,1.852-0.774,1.936-1.781l1.029-14.389   c0.053-0.319,0.319-0.585,0.638-0.585c0.319,0,0.638,0.266,0.638,0.585l1.252,14.424c0.101,0.992,0.936,1.746,1.933,1.746   c1.073,0,1.943-0.87,1.943-1.943V33.128L19.162,26.665z M11.617,24.884l-2.153-3.445l2.153-3.346V24.884z"
          }
        },
        {
          name: "path",
          attribs: {
            d: "M57.923,20.672l-3.989-6.577c0,0-1.204-1.226-4.53-1.902l-1.971,4.79l-0.327-2.761l0.728-1.099   c0.141-0.242,0.106-0.553-0.101-0.762c-0.122-0.123-0.295-0.184-0.469-0.184H45.9c-0.174,0-0.346,0.06-0.469,0.183   c-0.207,0.209-0.241,0.52-0.101,0.762l0.728,1.099l-0.327,2.763l-1.937-4.711c-3.029,0.707-4.368,1.783-4.368,1.783l-0.635,0.987   c1.125,0.552,1.679,1.066,1.83,1.22l0.145,0.147l4.096,6.754c0.687,1.132,0.658,2.577-0.074,3.683l-4.008,6.054V48.79   c0,1.073,0.87,1.943,1.943,1.943h0.273c1.01,0,1.852-0.774,1.936-1.781l1.029-14.389c0.053-0.319,0.319-0.585,0.638-0.585   c0.319,0,0.638,0.266,0.638,0.585l1.252,14.424c0.101,0.992,0.936,1.746,1.933,1.746h0c1.073,0,1.943-0.87,1.943-1.943V29.73   c0.466-0.056,0.907-0.308,1.188-0.732l4.335-6.548C58.242,21.914,58.256,21.221,57.923,20.672z M52.364,24.776v-3.604   c0-0.05,0.007-0.1,0.02-0.147v-3.079l2.152,3.55L52.364,24.776z"
          }
        },
        {
          name: "g",
          attribs: {},
          "children": [{
            name: "path", attribs: {
              d: "M55.306,40.599v4.458c1.915,1.452,2.965,3.032,2.965,4.558c0,3.154-4.397,5.239-7.019,6.202    C46.366,57.612,39.528,58.6,32,58.6c-7.528,0-14.366-0.988-19.252-2.783c-2.621-0.963-7.019-3.048-7.019-6.202    c0-1.521,1.043-3.095,2.946-4.543V40.61C4.347,43.079,2,46.214,2,49.615c0,2.841,1.642,6.831,9.463,9.703    C16.75,61.26,24.044,62.329,32,62.329c7.956,0,15.25-1.069,20.537-3.011C60.358,56.446,62,52.456,62,49.615    C62,46.209,59.647,43.07,55.306,40.599z"
            }
          }]
        }
      ]
    }
  ]
}

export default {
  viewBox: "0 0 367.627 248.29375",
  children: [
    {
      name: "g",
      attribs: {},
      children: [
        {
          name: "path",
          attribs: {
            d: "M243.33,29.792h-8.03v5.973c2.11-1.45,4.173-3.014,6.171-4.723C242.044,30.551,242.671,30.138,243.33,29.792z"
          }
        },
        {
          name: "path",
          attribs: {
            d: "M255.568,32.128c1.739,2.026,2.583,4.611,2.378,7.274c-0.112,1.458-0.542,2.838-1.231,4.079h1.248l6.882,13.121h-25.189   c-13.737,7.679-27.289,10.41-38.183,11.047l-5.532,12.91h-86.704L98.97,97.854h56.614c11.095,7.072,30.872,22.652,34.963,45.451   h-9.785c-4.233-21.146-22.889-37.073-45.285-37.082c-25.521,0.009-46.204,20.686-46.204,46.207c0,18.334,10.7,34.118,26.177,41.586   c6.061,2.926,12.843,4.613,20.027,4.618c22.396-0.01,41.052-15.935,45.285-37.077h64.127l36.885-72.666l13.392,25.549   c-12.042,8.338-19.939,22.232-19.939,37.99c0,25.518,20.684,46.194,46.205,46.204c25.516-0.01,46.195-20.686,46.195-46.204   c0-25.521-20.68-46.198-46.195-46.207c-4.875,0-9.566,0.766-13.975,2.166l-41.215-78.598h-13.627   C253.724,30.374,254.733,31.153,255.568,32.128z M161.856,161.558c-3.796,10.936-14.152,18.797-26.38,18.815   c-15.437-0.023-27.924-12.515-27.955-27.942c0.031-15.441,12.519-27.922,27.955-27.955c12.228,0.027,22.589,7.885,26.38,18.83   h-27.52v18.252H161.856z M303.75,130.804l13.898,26.516l12.125-6.355l-13.615-25.968c1.707-0.329,3.471-0.517,5.273-0.521   c15.428,0.033,27.924,12.514,27.939,27.955c-0.016,15.428-12.512,27.92-27.939,27.942c-15.438-0.023-27.925-12.515-27.955-27.942   C293.494,143.705,297.492,135.929,303.75,130.804z"
          }
        },
        {
          name: "path",
          attribs: {
            d: "M302.893,68.01V40.06c-7.719,0-13.975,6.257-13.975,13.977c0,1.371,0.258,2.672,0.624,3.93   C291.252,63.755,296.547,68.01,302.893,68.01z"
          }
        },
        {
          name: "ellipse",
          attribs: {
            transform: "matrix(0.0848 -0.9964 0.9964 0.0848 165.4364 225.1023)",
            cx: "205.251",
            cy: "22.497",
            rx: "22.495",
            ry: "22.494"
          }
        },
        {
          name: "path",
          attribs: {
            d: "M12.5,75.183h101.226c0,0,0.788,0,2.177,0c10.429,0,55.175,0,61.573,0c4.697,0,8.699-3.096,11.182-7.649   c-10.539-0.79-17.828-3.069-19.409-3.602c-2.595-0.903-4.622-2.715-5.782-5.121c-1.159-2.406-1.311-5.12-0.43-7.64   c1.402-4.009,5.194-6.702,9.439-6.702c1.123,0,2.231,0.189,3.3,0.562l0.133,0.043c0.221,0.068,0.544,0.168,0.988,0.295   c0.844,0.237,2.236,0.6,4.073,0.972c1.884,0.382,4.42,0.802,7.41,1.096c-3.179-5.287-9.01-9.902-17.847-11.577   c-20.943-3.842-34.148-3.407-55.764,2.338c-8.048,2.81-11.921,7.174-13.591,11.988l-88.675-0.001   c-6.905,0-12.501,5.598-12.503,12.5C0,69.587,5.598,75.183,12.5,75.183z"
          }
        },
        {
          name: "path",
          attribs: {
            d: "M176.354,47.291c-0.47-0.132-0.814-0.239-1.023-0.305c-0.107-0.032-0.176-0.056-0.212-0.068h-0.003   c-4.171-1.458-8.734,0.74-10.192,4.912c-1.459,4.169,0.74,8.734,4.912,10.192V62.02c0.568,0.189,10.905,3.778,26.031,3.79   c0.063,0,0.128,0,0.192,0c15.94,0.001,37.481-4.239,57.123-21.102c3.355-2.875,3.744-7.925,0.869-11.28   c-2.875-3.354-7.925-3.744-11.28-0.867c-16.282,13.927-33.557,17.215-46.904,17.249c-6.274,0-11.603-0.762-15.295-1.511   C178.728,47.925,177.291,47.556,176.354,47.291z"
          }
        }
      ]
    }
  ]
}
